import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { WebService } from '../../../services/web.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private passwordEmail: string = '';

  forgetPasswordSetupScreen: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  _forgetPasswordSetupScreen = this.forgetPasswordSetupScreen.asObservable();

  twofaSetupScreen: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  _twofaSetupScreen = this.twofaSetupScreen.asObservable();

  showTOTPVerify: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  _showTOTPVerify = this.showTOTPVerify.asObservable();
  
  public isloginScreenVisited = false;

  constructor(
    private webService: WebService,
    private http: HttpClient
  ) { }

  private host = environment.host();

  setVisited(visited: boolean) {
    this.isloginScreenVisited = visited;
    localStorage.setItem('isloginScreenVisited', JSON.stringify(this.isloginScreenVisited));
  }

  getVisitedStatus() {
    return this.isloginScreenVisited;
  }

  updateTOTPFlag(isVisible: any) {
    this.showTOTPVerify.next(isVisible);
  }

  updatePasswordScreen(passwordScNumber:any){
    this.forgetPasswordSetupScreen.next(passwordScNumber);
  }

  updateScreen(screenNumber:any){
    this.twofaSetupScreen.next(screenNumber);
  }

  setPasswordEmail(email: string): void {
    this.passwordEmail = email;
  }

  // Getter method to retrieve email
  getPasswordEmail(): string {
    return this.passwordEmail;
  }

  sendVerificationEmail(sysID: any){
    let requestUrl = this.host +'systemusers/' + sysID + '/generate-email-otp/';
    return this.webService.postAPI(requestUrl, {}, false); 
  }

  verifyEmailOTP(req: any,sysID: any) {
    let requestUrl = this.host +'systemusers/' + sysID + '/verify-email-otp/';
    return this.webService.postAPI(requestUrl, req, false); 
  }

  generateQR(sysID: any) {
    let requestUrl = this.host +'systemusers/' + sysID + '/generate-2fa-qr/';
    return this.webService.postAPI(requestUrl, {}, false); 
  }

  verifyFinalTOTP(req: any,sysID: any) {
    let requestUrl = this.host +'systemusers/' + sysID + '/setup-2fa/';
    return this.webService.postAPI(requestUrl, req, false); 
  }

  verifyLoginTOTP(req: any,sysID: any) {
    let requestUrl = this.host +'systemusers/' + sysID + '/verify-totp/';
    return this.webService.postAPI(requestUrl, req, false); 
  }

  sendResetPwdOtp(req: any) {
    let requestUrl = this.host +'accounts/systemusers/reset-password/';
    return this.webService.postAPI(requestUrl, req, false); 
  }

  setNewPassword(req: any) {
    let requestUrl = this.host +'accounts/do-reset-password/';
    return this.webService.postAPI(requestUrl, req, false); 
  }

  getSponsorData(id: any) {
    const requestUrl = environment.host() + 'sponsors/' + id + '/';
    return this.http.get(requestUrl);
  }

  fetchSponsorDocuments(id: any) {
    const requestUrl = environment.host() + 'sponsors/' + id + '/documents/?order_by=-created_ts&page_size=10';
    return this.http.get(requestUrl);
  }

}
